import React, { useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getQueryParams } from "../../utils"

const SignInPage = ({ location }) => {
  useEffect(() => {
    const verifyCode = getQueryParams(location.search)["verify-user"]
    if (verifyCode) {
      localStorage.setItem("verify-user", verifyCode)
    }
    window.location.href = "/cabinet/sign-in"
  }, [location])

  return (
    <Layout>
      <SEO title="Sign in" />
    </Layout>
  )
}

export default SignInPage
